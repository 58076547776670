<template>
  <section class="department-table-wrapper">
    <article class="table-wrapper">
      <div class="table-control">
        <div class="main">
          <el-button size="small" type="primary" @click="handleAdd" class="add">添加员工</el-button>
          <el-form inline size="small" class="form" ref="searchForm">
            <el-form-item>
              <el-input
                placeholder="员工ID"
                style="width: 100px"
                v-model="searchForm.platformUserId"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                placeholder="员工姓名/手机号"
                style="width: 140px"
                v-model="searchForm.userNameOrPhoneNumber"
              />
            </el-form-item>
            <el-form-item prop="post">
              <el-select
                filterable
                style="min-width: 80px"
                placeholder="岗位"
                v-model="searchForm.post"
                collapse-tags
              >
                <el-option
                  :value="postItem.postId"
                  :label="postItem.postName"
                  v-for="postItem in postList"
                  :key="postItem.postId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="isResign">
              <el-select style="width: 140px" placeholder="在职状态" v-model="searchForm.isResign">
                <!-- <el-option value="" label="全部"></el-option> -->
                <el-option :value="1" label="离职"></el-option>
                <el-option :value="0" label="在职"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="isDisable">
              <el-select style="width: 100px" placeholder="启用状态" v-model="searchForm.isDisable">
                <el-option :value="1" label="停用"></el-option>
                <el-option :value="0" label="启用"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="handleSearch">查询</el-button>
              <el-button @click="handleRest(searchForm)">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="extra">
          <el-dropdown trigger="click" @command="(command) => handleCommand(command, checkedRows)">
            <span class="el-dropdown-link">
              <el-button type="text">更多</el-button>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!--              <el-dropdown-item :command="1">导入</el-dropdown-item>-->
              <!--              <el-dropdown-item :command="2">导出</el-dropdown-item>-->
              <el-dropdown-item :command="3">停用</el-dropdown-item>
              <el-dropdown-item :command="4">启用</el-dropdown-item>
              <el-dropdown-item :command="5">离职</el-dropdown-item>
              <el-dropdown-item :command="6">在职</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div style="color: #999; padding: 10px 0">员工账号初始密码为：dpll20220308</div>
      <vxe-grid
        border
        :ref="tableRef"
        :data="dataSource"
        :columns="tableColumn"
        size="small"
        v-bind="tableOptions"
        v-loading="loading"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxAll"
      >
        <template #leader="{ row }">
          <el-button v-if="row.parentUserName" type="text" @click="queryLeader(row)">{{
            row.parentUserName
          }}</el-button>
          <span v-else>/</span>
        </template>
        <template #lower="{ row }">
          <el-button v-if="row.subUserNum" type="text" @click="queryLower(row)">{{
            row.subUserNum
          }}</el-button>
          <span v-else>{{ row.subUserNum }}</span>
        </template>
        <template #isDisable="{ row }">{{ row.isDisable === 0 ? '启用' : '停用' }}</template>
        <template #isResign="{ row }">{{ row.isResign === 1 ? '离职' : '在职' }}</template>
        <template #isOpenSeats="{ row }">
          {{ row.isOpenSeats === 0 ? '未开通' : '已开通' }}
        </template>
        <template #operate="{ row }">
          <el-button
            v-if="row.isDisable === 0"
            type="text"
            @click="handleChangePhoneSeat(row.isOpenSeats, row.userId)"
            >{{ row.isOpenSeats === 0 ? '开通坐席' : '关闭坐席' }}</el-button
          >
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="() => handleShowRestPassword(row)">密码重置</el-button>
          <el-button type="text" @click="() => handleShowIsDisableModal(row)">{{
            row.isDisable === 0 ? '停用' : '启用'
          }}</el-button>
          <!--          <el-button type='text' @click="()=>">删除</el-button>-->
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </article>
    <edit-user ref="modalForm" @ok="modalFormOk" />
  </section>
</template>

<script>
import { cloneDeep } from 'lodash';
import { resetUserPassword, changeSeateStatus, changeUsersIsResign } from '@/api/user';
import IPagination from '@/components/i-pagination/index.vue';
import TableListMinix from '@/minixs/TableListMinix';
import EditUser from './EditUser.vue';
import { postAction } from '@/api/manage';

export default {
  name: 'DepartmentTable',
  mixins: [TableListMinix],
  props: {
    departmentId: {
      type: String,
      default: ''
    }
  },
  components: {
    IPagination,
    EditUser
  },
  data() {
    return {
      tableRef: 'DepartmentTable',
      modalForm: 'modalForm',
      queryParams: {
        department: []
      },
      defaultForm: {
        post: '', // 岗位
        isResign: '', // 在职离职
        isDisable: '', // 是否禁用
        platformUserId: '',
        userNameOrPhoneNumber: '' // 用户名或手机号
      },
      postList: [],
      searchForm: {
        post: '',
        isResign: '', // 在职离职
        isDisable: '', // 是否禁用
        platformUserId: '',
        userNameOrPhoneNumber: '' // 用户名或手机号
      },
      url: {
        list: 'user-web-api/admin/user/info/platform/pageList',
        status: 'user-web-api/admin/user/info/platform/updateDisable'
      },
      tableColumn: [
        { type: 'checkbox', width: 50, fixed: 'left' },
        { field: 'userName', title: '员工姓名', fixed: 'left', width: 100 },
        { field: 'platformUserId', title: '员工ID', width: 100 },
        { field: 'phoneNumber', title: '手机号码', width: 150 },
        { field: 'departmentNames', title: '部门名称', width: 100 },

        // { field: 'enterpriseWechat', title: '企业微信号', width: 100 },
        { field: 'postNames', title: '岗位', width: 285 },
        { title: '直属上级', slots: { default: 'leader' }, width: 100 },
        { title: '下级', slots: { default: 'lower' }, width: 100 },
        { field: 'rankName', title: '职级', width: 100 },
        { field: 'workingTime', title: '在职时间', width: 150 },
        {
          field: 'isOpenSeats',
          title: '是否已开通坐席',
          width: 80,
          slots: { default: 'isOpenSeats' }
        },
        { field: 'isResign', title: '在职状态', width: 100, slots: { default: 'isResign' } },
        { field: 'status', title: '启用状态', width: 100, slots: { default: 'isDisable' } },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right', width: 240 }
      ],
      tableData: [],
      tableOptions: {
        checkboxConfig: {
          // checkField:'rankId',
          checkRowKeys: [],
          reserve: true // 保留勾选状态
        }
      }
    };
  },
  watch: {
    departmentId: {
      handler(newVal) {
        this.iPagination.currentPage = 1;
        if (newVal) {
          this.queryParams.department = [newVal];
        } else {
          this.queryParams.department = [];
        }

        this.loadData();
      },
      immediate: true
    }
  },
  mounted() {
    this.getPostList();
  },
  methods: {
    getQueryParams(otherParams) {
      const params = {};
      params.pageNumber = this.iPagination.currentPage;
      params.pageSize = this.iPagination.pageSize;
      const searchParams = cloneDeep(this.searchForm);

      searchParams.post =
        this.searchForm.post && this.searchForm.post.length > 0 ? [this.searchForm.post] : [];
      params.data = otherParams
        ? { ...searchParams, ...this.queryParams, ...otherParams }
        : { ...searchParams, ...this.queryParams };
      return params;
    },

    handleSearch() {
      this.iPagination.currentPage = 1;
      this.loadData();
    },
    // 重置
    handleRest() {
      this.searchForm.post = '';
      this.searchForm.isResign = '';
      this.searchForm.isDisable = '';
      this.searchForm.platformUserId = '';
      this.searchForm.userNameOrPhoneNumber = '';
      this.iPagination.currentPage = 1;
      this.iPagination.pageSize = 10;
      this.loadData();
    },
    async getPostList() {
      try {
        const params = { data: { pageNumber: 1, pageSize: 1000, data: {} } };
        postAction('user-web-api/admin/post/getPostList', params).then((res) => {
          if (res.success === false) {
            this.$message.error(res.description);
            return;
          }

          this.postList = res;
        });
      } catch (e) {}
    },
    handleCommand(command, checkedRows) {
      if (command === 1) {
        // TODO 导入
      }
      if (command === 2) {
        //  TODO 导出
      }
      if (command === 3) {
        this.handleShowMultipleIsDisableModal(1);
      }

      if (command === 4) {
        // 启用
        this.handleShowMultipleIsDisableModal(0);
      }
      if (command === 5) {
        // TODO 离职
        this.handleChangeUserListReginState(1);
      }
      if (command === 6) {
        // TODO 在职
        this.handleChangeUserListReginState(0);
      }
    },
    // 单个启用禁用
    handleShowIsDisableModal(row) {
      const that = this;
      const params = {
        data: { userIds: [row.userId], isDisable: row.isDisable === 0 ? 1 : 0 }
      };
      const message = row.isDisable === 0 ? '是否确定停用？' : '是否确定启用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleSetIsDisable(params);
        })
        .catch(() => {});
    },
    // 多个启用禁用
    handleShowMultipleIsDisableModal(isDisable) {
      const that = this;
      const userIds = this.checkedRows.map((item) => item.userId);
      if (userIds.length === 0) {
        this.$message.closeAll();
        this.$message({
          message: '至少选择一条数据',
          type: 'warning'
        });
        return;
      }
      const params = {
        data: { userIds, isDisable }
      };
      const message = isDisable === 0 ? '是否确定启用？' : '是否确定停用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await that.handleSingleSetIsDisable(params, 2);
          console.log(res, '多个停用启用');
          if (res.success === false) {
            this.$message.error(res.description);
            return;
          }
          this.loadData();
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 密码重置
    handleShowRestPassword(row) {
      const params = {
        data: [row.userId]
      };
      const message = '是否确定重置密码为“dpll20220308”？';
      this.$confirm(message, '重置密码确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          // TODO 调用密码重置接口
          const res = await resetUserPassword(params);

          if (res.success === false) {
            this.$message.error(res.description);
            return;
          }
          this.$message.success('重置密码成功');
        })
        .catch(() => {});
    },
    // 开通关闭坐席
    handleChangePhoneSeat(status, userId) {
      let message = '';
      if (status === 0) {
        message = '确定要开通坐席吗？添加后，该员工可拨打电话';
      } else {
        message = '确定要关闭坐席吗？关闭后，该员工无法拨打电话';
      }

      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const params = {
            data: {
              userId,
              isOpenSeats: status === 0 ? 1 : 0
            }
          };
          const res = await changeSeateStatus(params);
          if (res.success === false) {
            this.$message.error(res.description);
            return;
          }
          this.$message.success('操作成功');
          this.loadData();
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 批量离职 在职
    async handleChangeUserListReginState(isResign) {
      try {
        const userIds = this.checkedRows.map((item) => item.userId);
        const params = {
          data: {
            userIds,
            isResign
          }
        };
        const res = await changeUsersIsResign(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.$message.success('操作成功');
        this.loadData();
      } catch {}
    },
    queryLeader(row) {
      this.searchForm.post = '';
      this.searchForm.isResign = '';
      this.searchForm.isDisable = '';
      this.searchForm.platformUserId = '';
      this.searchForm.userNameOrPhoneNumber = '';
      this.iPagination.currentPage = 1;
      this.iPagination.pageSize = 10;
      this.loadData({ userId: row.parentUserId });
    },
    queryLower(row) {
      this.searchForm.post = '';
      this.searchForm.isResign = '';
      this.searchForm.isDisable = '';
      this.searchForm.platformUserId = '';
      this.searchForm.userNameOrPhoneNumber = '';
      this.iPagination.currentPage = 1;
      this.iPagination.pageSize = 10;
      this.loadData({ parentUserId: row.userId });
    }
  }
};
</script>

<style scoped lang="less">
.department-table-wrapper {
  padding-top: 10px;
  .table-wrapper {
    background: #fff;
    padding: 0 20px;

    .table-control {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .main {
        display: flex;
        align-items: center;

        .add {
          flex-shrink: 0;
          margin-right: 10px;
        }

        /deep/ .el-form--inline .el-form-item {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
